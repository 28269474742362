import { useNavigation, useSearchParams } from "@remix-run/react";
import { useEffect } from "react";

import { segment } from "~/lib/segment";

export function Segment() {
  // Remix hooks
  const navigation = useNavigation();
  const [searchParams] = useSearchParams();

  // First page view
  useEffect(() => {
    // TODO: Grab uuid from searchParams like this
    // const hubspotId = searchParams.get("hubspotId");
    segment.identify();
    segment.page();
  }, []);

  // Runs on page navigations
  useEffect(() => {
    if (navigation.state === "loading") {
      segment.page(navigation.location);
    }
  }, [navigation.state]);

  return null;
}
